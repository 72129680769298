<template>
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <global-table :tableField="tableField" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>
  </div>
</template>

<script>
import ListSearch from '@/components/actionList/ListSearch.vue'
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import { SearchList, SearchData, tableField, tableData } from './js/setting.js'
import { request } from '@/assets/js/http.js'
import dayjs from 'dayjs'

export default {
  components: { ListSearch, GlobalTable },
  name: 'BidNotice',
  data () {
    return {
      tableField: tableField,
      tableData: tableData,
      paginationData: {},
      pageNum: 1,
      pageSize: 10,
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },

  created () {
    this.pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (this.pageSize < 10) {
      this.pageSize = 10
    }
    request('/api/bid/listBySupplier?' + 'pageNum=' + this.pageNum + '&' + 'pageSize=' + this.pageSize, 'POST', {}).then((res) => {
      if (res.code === '200') {
        this.tableData = res.data.records
        this.paginationData = { pageSize: this.pageSize, total: res.data.total }
      }
    })
    this.myBidData()
  },
  methods: {
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD HH:mm:ss')
    },
    buttonClick: function (data) {
      this.$router.push({ params: { viewName: 'BidNoticeRating' } })
    },
    // 获取招标公告列表数据
    myBidData () {
      var searchdata = { ...this.childData.searchData }
      delete searchdata.tempCreateDate
      if (this.childData.searchData.tempCreateDate) {
        searchdata.releaseEndTime = this.dateFormate(this.childData.searchData.tempCreateDate[1])
        searchdata.releaseStartTime = this.dateFormate(this.childData.searchData.tempCreateDate[0])
      }
      delete searchdata.tempBidEndDate
      if (this.childData.searchData.tempBidEndDate) {
        searchdata.bidEndTime = this.dateFormate(this.childData.searchData.tempBidEndDate[1])
        searchdata.bidStartTime = this.dateFormate(this.childData.searchData.tempBidEndDate[0])
      }
      request('/api/bid/listBySupplier?' + 'pageNum=' + this.pageNum + '&' + 'pageSize=' + this.pageSize, 'POST', searchdata).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
        }
      })
    },
    // 点击操作里的按钮进行操作
    tableAction: function (index, data, clickBtn) {
      this.$router.push({ params: { viewName: 'BidDetails' }, query: { id: data.bidId, status: data.status } })
      this.$emit('OpenView', { openView: 'BidDetails' })
    },
    // 查询
    onSearch (data) {
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      this.myBidData()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.myBidData()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
</style>
