export var SearchList = [
  { labe: '招标编号', code: 'bidCode', type: 'input' },
  { labe: '项目名称', code: 'itemName', type: 'input' },
  {
    labe: '发布日期',
    code: 'tempCreateDate',
    type: 'daterange'
  },
  { labe: '公司名称', code: 'company', type: 'input', placeholder: '编码/名称' },
  {
    labe: '投标截止日期',
    code: 'tempBidEndDate',
    type: 'daterange'
  }
]
export var SearchData = {
  bidCode: '',
  itemName: '',
  createDate: '',
  company: '',
  bidEndDate: ''
}
export var tableField = [
  { label: '招标编号', code: 'bidCode', type: 'input', width: '30%' },
  { label: '项目名称', code: 'itemName', type: 'input', width: '50%' },
  { label: '公司名称', code: 'companyName', type: 'input', width: '50%' },
  { label: '发布日期', code: 'createDate', type: 'input', width: '45%' },
  { label: '发布人', code: 'createUserName', type: 'input', width: '20%' },
  { label: '投标截止日期', code: 'bidEndDate', type: 'input', width: '45%' },
  {
    label: '操作',
    code: 'act',
    width: '15%',
    type: 'action',
    actionData: [
      {
        actionLabel: '查看',
        operationButton: 'see'
      }
    ]
  }
]

export var tableData = []
